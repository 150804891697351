import axiosInstance from "../utils/axios";

export const vehiclesService = {
  getPricingData: async () => {
    try {
      return axiosInstance.get(`/vehicles/pricingData`);
    } catch (error) {
      throw error;
    }
  },
  editPricingData: async (data) => {
    try {
      return axiosInstance.put(`/vehicles/editPricingData`, data);
    } catch (error) {
      throw error;
    }
  },
};
