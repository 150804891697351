import TripsTable from "./tripsTable";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import {
  Text,
  Input,
  useToast,
  Box,
  Center,
  Button,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";
import { BsBarChartLine } from "react-icons/bs";
import { adminService } from "../../services/admins";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BsExclamationCircleFill } from "react-icons/bs";
import { MdCloudDone } from "react-icons/md";
import { MdOutlinePending } from "react-icons/md";
import { BsPieChartFill } from "react-icons/bs";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import TripsTab from "./trips";
import SpecialRequests from "./special-requests";

const Trips = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [statistics, setStatistics] = useState({
    totalTrips: 0,
    totalFinishedTrips: 0,
    totalPendingTrips: 0,
    totalRejectedTrips: 0,
    totalAmount: 0,
  });

  const [boxes, setBoxes] = useState([
    {
      label: "Total Trips",
      icon: <BsPieChartFill size={"30px"} color={"#3e98d4"} />,
      key: "totalTrips",
    },
    {
      label: "Finished Trips",
      icon: <MdCloudDone size={"30px"} color={"#0ED27D"} />,
      key: "totalFinishedTrips",
    },
    {
      label: "Pending Trips",
      icon: <MdOutlinePending size={"30px"} color={"#2B6FF2"} />,
      key: "totalPendingTrips",
    },
    {
      label: "Rejected Trips",
      icon: <BsExclamationCircleFill size={"30px"} color={"#F43A4D"} />,
      key: "totalRejectedTrips",
    },
    {
      label: "Total Amount",
      icon: <RiMoneyDollarCircleFill size={"30px"} color={"#F7A01F"} />,
      key: "totalAmount",
    },
  ]);

  const getTripsStatistics = async (noload = false) => {
    try {
      setLoadingStatistics(noload ? false : true);
      const res = await adminService.getTripsStatistics();
      setStatistics({
        totalTrips: res?.data?.totalTrips,
        totalFinishedTrips: res?.data?.totalFinishedTrips,
        totalPendingTrips: res?.data?.totalPendingTrips,
        totalRejectedTrips: res?.data?.totalRejectedTrips,
        totalAmount: res?.data?.totalAmount,
      });
      setLoadingStatistics(false);
    } catch (error) {
      console.log(error);
      setLoadingStatistics(false);
    }
  };

  useEffect(() => {
    getTripsStatistics();
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const tab = searchParams.get("tab");
    setTabIndex(parseInt(tab));
  }, [searchParams]);

  const onChangeTab = (index) => {
    const page = searchParams.get("page");
    navigate(`/trips?page=1&tab=${index}`);
  };

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Flex
          flexWrap={"wrap"}
          rowGap={"15px"}
          justifyContent={"space-between"}
          mb={"30px"}
          columnGap={"23px"}
        >
          {boxes?.map((box, index) => {
            return (
              <Box
                flex={1}
                key={"statistics_" + index}
                bg={"white"}
                p={"20px"}
                px={"40px"}
                rounded={"md"}
                boxShadow={"xs"}
              >
                <Box mb={"10px"}>{box?.icon}</Box>
                <Text color={"#788698"} fontSize={17}>
                  {box?.label}
                </Text>
                {loadingStatistics ? (
                  <Box pt={"5px"}>
                    <Spinner size={"md"} color={"#3e98d4"} />
                  </Box>
                ) : (
                  <Text color={"#000"} fontSize={22} fontWeight={"bold"}>
                    {index == 4 && "KES "}
                    {statistics[box.key]?.toLocaleString()}
                  </Text>
                )}
              </Box>
            );
          })}
        </Flex>

        <Tabs
          index={tabIndex}
          onChange={onChangeTab}
          variant="soft-rounded"
          colorScheme="primary"
        >
          <TabList>
            <Tab>Trips</Tab>
            <Tab>Special Requests</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>{tabIndex == 0 && <TripsTab />}</TabPanel>
            <TabPanel>{tabIndex == 1 && <SpecialRequests />}</TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default Trips;
