export const vehicleTypes = [
  { value: "TAXI", label: "Taxi" },
  { value: "VAN", label: "Van" },
  { value: "BUS", label: "Bus" },
  { value: "MINIVAN", label: "Minivan" },
];

export function getRejectionReason(reason) {
  let message;
  switch (reason) {
    case "DESTINATION_TOO_FAR":
      message =
        "The destination is too far from the driver's current location.";
      break;
    case "HIGH_TRAFFIC_AREA":
      message = "The trip involves a high traffic area, causing delays.";
      break;
    case "PERSONAL_EMERGENCY":
      message =
        "The driver is unable to take the trip due to a personal emergency.";
      break;
    case "VEHICLE_ISSUE":
      message = "The driver is experiencing a vehicle issue.";
      break;
    case "NOT_SAFE_AREA":
      message = "The driver is concerned about safety in the area.";
      break;
    case "LOW_FARE":
      message = "The fare is too low for the trip.";
      break;
    case "EXCESSIVE_WAIT_TIME":
      message = "The driver has been waiting too long for the passenger.";
      break;
    case "PASSENGER_BEHAVIOR":
      message = "The driver has concerns about the passenger's behavior.";
      break;
    case "TRIP_ALREADY_ACCEPTED":
      message = "The trip was already accepted by another driver.";
      break;
    case "ROUTE_TOO_LONG":
      message = "The route is too long for the driver to accept.";
      break;
    case "OTHER":
      message =
        "The driver has another unspecified reason for rejecting the trip.";
      break;
    default:
      message = "Unknown reason for trip rejection.";
  }

  return message;
}

export const currencyCodes = [
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "FOK",
  "GBP",
  "GEL",
  "GGP",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "IMP",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JEP",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KID",
  "KMF",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRU",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STN",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TVD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "UYU",
  "UZS",
  "VES",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XCD",
  "XOF",
  "XPF",
  "YER",
  "ZAR",
  "ZMW",
  "ZWL",
];
