import PassengersTable from "./passengersTable";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { Text, Input, useToast, Box, Center, Button } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";
import { adminService } from "../../services/admins";

const Passengers = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  useEffect(() => {
    const page = searchParams.get("page");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate("/passengers?page=1");
  }, [searchParams]);

  const headers = [
    {
      name: "Username",
    },
    {
      name: "Phone Number",
    },
    {
      name: "Email",
    },
    {
      name: "Total Trips",
    },
    {
      name: "Status",
    },
    {
      name: "Created At",
    }
  ];

  const getPassengers = async (limit, pageNum) => {
    try {
      setLoading(true);
      const res = await adminService.getPassengers(limit, pageNum);
      setTrips(res?.data?.passengers);
      setTotalNum(res.data?.totalPassengers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve passengers",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getPassengers(itemsPerPage, pageNum);
  }, [pageNum]);

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Box className="table-nav">
          <Box mb={"10px"} className="tb-title">
            <Text>Passengers</Text>
          </Box>
        </Box>

        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <PassengersTable
            headers={headers}
            data={trips}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            setSortBy={"created_at"}
            searching={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default Passengers;
