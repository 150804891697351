import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TbDotsVertical } from "react-icons/tb";
import PricingModal from "../../components/common/pricingModal";
import { vehiclesService } from "../../services/vehicles";
import { vehicleTypes } from "../../utils/helpers";

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [loading, setLoading] = useState(true);
  const tds = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const rows = [1, 2, 3, 4];
  const [pricing, setPricingData] = useState([]);
  const toast = useToast();

  const getPricingData = async () => {
    try {
      setLoading(true);
      const res = await vehiclesService.getPricingData();
      setPricingData(res?.data?.pricingData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPricingData();
  }, []);

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Box className="table-nav"></Box>
        <Flex rowGap={"40px"}>
          <Box w={"100%"} bg={"white"} py={"20px"} px={"20px"}>
            <Text className="tb-title" mb={"15px"}>
              Pricing Info
            </Text>

            <Table className="customers-table drivers-table pricing-table">
              <Thead px={"0px"}>
                <Tr>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Vehicle
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Hours/Day
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Hours/Night
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Daily
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Airport
                      <br />
                      (One Way)
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Airport
                      <br />
                      (Two Way)
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Luggage
                      <br />
                      Price
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Protocol
                      <br />
                      Price
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Action
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  rows?.map((r) => {
                    return (
                      <>
                        <Tr>
                          {tds?.map((d) => {
                            return (
                              <Td>
                                <Box py={"7px"}>
                                  <Skeleton
                                    startColor={"#F4F4F4"}
                                    borderRadius={"20px"}
                                    endColor={"#dddddd"}
                                    h={"20px"}
                                  />
                                </Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    {!loading && pricing?.length == 0 ? (
                      <>
                        <Tr _hover={{ bg: "white !important" }}>
                          <Td
                            colSpan={12}
                            bg={"white"}
                            _hover={{ bg: "white" }}
                          >
                            <Center
                              bg={"white"}
                              py={"45px"}
                              w={"100%"}
                              minW={"100%"}
                            >
                              <Center>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No pricing & timing data available
                                </Text>
                              </Center>
                            </Center>
                          </Td>
                        </Tr>
                      </>
                    ) : (
                      <>
                        {pricing?.map((data) => {
                          return (
                            <Tr>
                              <Td>
                                <Flex columnGap={"6px"} alignItems={"center"}>
                                  <Flex
                                    alignItems={"center"}
                                    width={"50px"}
                                    height={"50px"}
                                  >
                                    <Image
                                      objectFit={"contain"}
                                      maxWidth={"100%"}
                                      maxHeight={"100%"}
                                      src={data?.iconUrl}
                                    />
                                  </Flex>
                                  {
                                    vehicleTypes.find(
                                      (v) => v.value == data?.vehicleType
                                    )?.label
                                  }
                                </Flex>
                              </Td>
                              <Td>
                                {data?.currency}{" "}
                                {data?.pricePerHourDay?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency}{" "}
                                {data?.pricePerHourNight?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency}{" "}
                                {data?.dailyPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency}{" "}
                                {data?.airportOneWayPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency}{" "}
                                {data?.airportTwoWayPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency}{" "}
                                {data?.luggagePrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency}{" "}
                                {data?.protocolPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                <Menu>
                                  <MenuButton bg={"transparent"} as={Button}>
                                    <TbDotsVertical size={22} />
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        setCurrentVehicle(data);
                                        onOpen();
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Td>
                            </Tr>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </Tbody>
            </Table>
          </Box>
        </Flex>

        <PricingModal
          isOpen={isOpen}
          vehicle={currentVehicle}
          onOpen={onOpen}
          onClose={onClose}
          onSuccess={(message) => {
            toast({
              position: "top",
              description: message,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
            getPricingData();
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
        />
      </Box>
    </>
  );
};

export default Dashboard;
