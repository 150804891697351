import { Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const CountDown = ({ trip, startDate }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [amount, setAmount] = useState(trip?.initialPrice || 0);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const start = new Date(startDate);
      const end = trip?.endedAt ? new Date(trip.endedAt) : null;
      let diff = now - start;

      // If there's an end date, stop the timer when the current time passes the end date
      if (end && now >= end) {
        diff = end - start;
        clearInterval(interval); // Stop the interval when end date is reached
      }

      if (diff >= 0) {
        const totalSeconds = Math.floor(diff / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const seconds = totalSeconds % 60;

        // Calculate the amount based on time elapsed in minutes (exclude seconds)
        const pricePerMinute = trip?.initialPrice / 60;
        const additionalAmount = totalMinutes > 60 
          ? totalMinutes * pricePerMinute 
          : trip?.initialPrice;

        setStarted(true);
        setTimeRemaining({ hours, minutes, seconds });
        setAmount(additionalAmount);
      }
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [startDate, trip?.initialPrice, trip?.endedAt]);

  return (
    <>
      {started && (
        <Box
          w={"100%"}
          rounded={"lg"}
          bg={"#F7A01F"}
          p={"20px"}
          py={"15px"}
          mb={"15px"}
          textAlign={"center"}
        >
          <div>
            <p className="timer-title">Timer</p>
            <h1 className="count-up-values">
              {String(timeRemaining.hours).padStart(2, "0")}:
              {String(timeRemaining.minutes).padStart(2, "0")}:
              {String(timeRemaining.seconds).padStart(2, "0")}
            </h1>
            <h2 className="timer-amount">KES {amount?.toLocaleString()}</h2>
          </div>
        </Box>
      )}
    </>
  );
};

export default CountDown;