import axiosInstance from "../utils/axios";

export const authService = {
  registerAdmin: async (data) => {
    try {
      return axiosInstance.post("/admins/", data);
    } catch (error) {
      throw error;
    }
  },
  loginAdmin: async (data) => {
    try {
      return axiosInstance.post("/admins/login", data);
    } catch (error) {
      throw error;
    }
  },
  getCurrentAdmin: async () => {
    try {
      return axiosInstance.get(`/admins/currentAdmin`);
    } catch (error) {
      throw error;
    }
  },
};
