import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Flex,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Image,
  Text,
  Center,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { authService } from "../../services/auth";
import { vehicleTypes } from "../../utils/helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { driversService } from "../../services/drivers";
import { IoMdClose } from "react-icons/io";

export default function DriverInfoModal({
  isOpen,
  onClose,
  onRegister,
  onError,
  driver,
  onApprove,
}) {
  const [data, setData] = useState({});
  const [vehicleImages, setVehicleImages] = useState([]);

  useEffect(() => {
    console.log("driver", driver);
    const { vehicles } = driver;
    if (vehicles?.length > 0) {
      setData({ ...vehicles[0], ...driver });
      setVehicleImages(vehicles[0].images);
    }
  }, [driver]);

  const imageInput = useRef();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const cloudinaryUrl =
    "https://api.cloudinary.com/v1_1/duldhdjsj/image/upload";

  const update = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (vehicleImages?.length == 0) {
        onError("Upload atleast one image of the vehicle");
        setLoading(false);
        return;
      }

      const formDataArray = vehicleImages
        .filter((img) => !img.toString().startsWith("https"))
        .map((base64Image) => {
          const formData = new FormData();
          formData.append("file", base64Image);
          formData.append("upload_preset", "wtmnkwiw");
          return formData;
        });

      const uploadPromises = formDataArray.map((formData) =>
        fetch(cloudinaryUrl, {
          method: "POST",
          body: formData,
        }).then((response) => response.json())
      );

      const results = await Promise.all(uploadPromises);
      const uploadedImages = results.map((result) => result.secure_url);

      const { color, vehicleType, seats, plateNumber, model } = data;
      const { fullName, gender, phoneNumber } = data;
      const res = await driversService.updateDriver({
        vehicle: {
          color,
          vehicleType,
          seats,
          model,
          images: [
            ...vehicleImages.filter((img) =>
              img.toString().startsWith("https")
            ),
            ...uploadedImages,
          ],
          plateNumber,
        },
        fullName,
        gender,
        phoneNumber: (phoneNumber?.startsWith("+") ? "" : "+") + phoneNumber,
        userId: driver?.id,
        vehicleId: driver.vehicles[0]?.id,
      });

      setLoading(false);
      onRegister();
    } catch (error) {
      setLoading(false);
      console.log(error);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  function handleFiles(event) {
    const files = event.target.files;
    const urls = [];

    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const imageUrl = event.target.result;
        urls.push(imageUrl);
        if (files.length == urls.length) {
          setVehicleImages([...vehicleImages, ...urls]);
        }
      };

      reader.readAsDataURL(file);
    });
  }

  const approveDriver = async () => {
    try {
      setLoading2(true);
      const res = await driversService.approveDriver(driver.id);
      onApprove("Driver approved successfully!");
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Modal isCentered={true} size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{driver?.fullName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w={"100%"} onSubmit={update} as={"form"}>
            <input
              maxLength={5}
              onChange={handleFiles}
              style={{ display: "none" }}
              type="file"
              ref={imageInput}
              multiple
              accept="image/*"
            />

            <Flex columnGap={5} mb={"10px"}>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Phone Number
                </FormLabel>
                <PhoneInput
                  enableSearch={true}
                  country={"ke"}
                  placeholder="Enter phone number"
                  value={data.phoneNumber}
                  containerClass="phone-number-container"
                  buttonClass={"buttonClass"}
                  inputClass={"phone-number-input"}
                  inputStyle={{
                    width: "100%",
                    paddingTop: "22px",
                    paddingBottom: "22px",
                    fontSize: "16px",
                  }}
                  containerStyle={{ width: "100%" }}
                  onChange={(val) => {
                    setData({ ...data, phoneNumber: val });
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Full name
                </FormLabel>
                <Input
                  py={"22px"}
                  value={data.fullName}
                  onChange={(e) => {
                    setData({ ...data, fullName: e.target.value });
                  }}
                  type={"text"}
                  placeholder="Full name"
                />
              </FormControl>
            </Flex>

            <FormControl isRequired mb={"10px"}>
              <RadioGroup
                onChange={(val) => {
                  setData({ ...data, gender: val });
                }}
                value={data.gender}
              >
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Gender
                </FormLabel>
                <Stack columnGap={7} direction="row">
                  <Radio value="MALE">Male</Radio>
                  <Radio value="FEMALE">Female</Radio>
                  <Radio value="OTHER">Other</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <Flex columnGap={5} mb={"10px"}>
              <FormControl>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle type
                </FormLabel>
                <Select
                  fontWeight="medium"
                  fontSize="14px"
                  h={"45px"}
                  value={data.vehicleType}
                  mb={"2px"}
                  placeholder="Select vehicle type"
                  onChange={(e) => {
                    setData({ ...data, vehicleType: e.target.value });
                  }}
                >
                  {vehicleTypes?.map((type) => {
                    return <option value={type?.value}>{type?.label}</option>;
                  })}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle Model
                </FormLabel>
                <Input
                  py={"22px"}
                  value={data?.model}
                  onChange={(e) => {
                    setData({ ...data, model: e.target.value });
                  }}
                  type={"text"}
                  placeholder="Ex: Nissan, Benz, Corolla, Rava4, etc"
                />
              </FormControl>
            </Flex>

            <FormControl isRequired mb={"13px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Upload vehicle image(s)
              </FormLabel>
              <Box
                py={"30px"}
                px={"30px"}
                onClick={() => {
                  imageInput.current.click();
                }}
                borderRadius={"12px"}
                borderStyle={"dashed"}
                borderWidth={3}
                borderColor={"lightgray"}
                bg={"#F8FAFB"}
                mt={"5px"}
                textAlign={"center"}
                cursor={"pointer"}
              >
                <Button colorScheme={"primary"}>Browse</Button>
              </Box>
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                columnGap={"10px"}
                rowGap={"10px"}
                pt={"10px"}
              >
                {vehicleImages?.map((img, index) => {
                  return (
                    <Center
                      boxShadow={"xs"}
                      pos={"relative"}
                      width={"150px"}
                      height={"150px"}
                      key={"img" + index}
                    >
                      <Image
                        borderRadius={"4px"}
                        objectFit={"contain"}
                        maxHeight={"100%"}
                        maxWidth={"100%"}
                        objectPosition={"top"}
                        src={img}
                      />
                      <button
                        onClick={() => {
                          setVehicleImages(
                            vehicleImages.filter((imgs) => imgs != img)
                          );
                        }}
                        type="button"
                        className="closeBtn"
                      >
                        <IoMdClose size={20} />
                      </button>
                    </Center>
                  );
                })}
              </Box>
            </FormControl>

            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Vehicle Plate number
              </FormLabel>
              <Input
                value={data.plateNumber}
                py={"22px"}
                onChange={(e) => {
                  setData({ ...data, plateNumber: e.target.value });
                }}
                type={"text"}
                placeholder="Enter a plate number"
              />
            </FormControl>

            <Flex columnGap={5} mb={"10px"}>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Color of the vehicle
                </FormLabel>
                <Input
                  py={"22px"}
                  value={data.color}
                  onChange={(e) => {
                    setData({ ...data, color: e.target.value });
                  }}
                  type={"text"}
                  placeholder="Color of the vehicle"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Number Of Seats
                </FormLabel>
                <Input
                  value={data.seats}
                  py={"22px"}
                  onChange={(e) => {
                    setData({ ...data, seats: parseInt(e.target.value) });
                  }}
                  type={"number"}
                  placeholder="Number of Seats"
                />
              </FormControl>
            </Flex>

            {driver.drivingLicences?.length > 0 && (
              <Box mb={"10px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Driving License documents
                </FormLabel>

                <Flex columnGap={5}>
                  <Box w={"50%"}>
                    <Text fontWeight={"bold"} fontSize={"14px"} mb={"2px"}>
                      Front
                    </Text>
                    <Image src={driver.drivingLicences[0]} />
                  </Box>
                  <Box w={"50%"}>
                    <Text fontWeight={"bold"} fontSize={"14px"} mb={"2px"}>
                      Back
                    </Text>
                    <Image src={driver.drivingLicences[1]} />
                  </Box>
                </Flex>
              </Box>
            )}

            <ModalFooter px={0} justifyContent={"space-between"}>
              {driver.accountStatus == "PENDING" ? (
                <Button
                  colorScheme="success"
                  type={"button"}
                  isLoading={loading2}
                  loadingText={"Approving..."}
                  onClick={() => {
                    approveDriver();
                  }}
                >
                  Approve Driver
                </Button>
              ) : (
                <Box></Box>
              )}
              <Flex columnGap={3}>
                <Button type={"button"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  loadingText={"Saving..."}
                  isLoading={loading}
                  type={"submit"}
                  colorScheme="primary"
                >
                  Update
                </Button>
              </Flex>
            </ModalFooter>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
