import Axios from 'axios';

const baseURL = "https://wanaapp.com/api"
// const baseURL = "http://localhost:3030/api"
// const baseURL = "http://38.242.200.169:3030/api";


const axiosInstance = Axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  async function (config) {
      const token = localStorage.getItem('access_token');
      config.headers.Authorization = `Bearer ${token}`;
      config.timeout = 300000;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
export default axiosInstance;
