import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Authentication/Login";
import AppLayout from "./components/common/app-layout";
import Admins from "./pages/admins";
import Drivers from "./pages/drivers";
import Logout from "./logout";
import Dashboard from "./pages/dashboard";
import Trips from "./pages/trips";
import Passengers from "./pages/passengers";
import DriverPerformance from "./pages/drivers/driverPerformance";

export default function AppRoutes() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/admins"
          element={
            <AppLayout>
              <Admins />
            </AppLayout>
          }
        />
        <Route
          path="/dashboard"
          element={
            <AppLayout>
              <Dashboard />
            </AppLayout>
          }
        />
        <Route
          path="/drivers"
          element={
            <AppLayout>
              <Drivers />
            </AppLayout>
          }
        />
        <Route
          path="/drivers/performance/:id"
          element={
            <AppLayout showBackButton={true}>
              <DriverPerformance />
            </AppLayout>
          }
        />
        <Route
          path="/passengers"
          element={
            <AppLayout>
              <Passengers />
            </AppLayout>
          }
        />
        <Route
          path="/trips"
          element={
            <AppLayout>
              <Trips />
            </AppLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
