import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
} from "@chakra-ui/react";
import { useState } from "react";
import { authService } from "../../services/auth";

export default function RegisterAdminModal({
  isOpen,
  onClose,
  onRegister,
  onError,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const add = async (e) => {
    try {
      e.preventDefault();
      if (data.email.trim().length > 0 && data.username.trim().length > 0) {
        setLoading(true);
        await authService.registerAdmin(data);
        setLoading(false);
        return onRegister();
      } else {
        onError("Email and username are required");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Modal isCentered={true} size={"lg"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Register Admin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w={"100%"} onSubmit={add} as={"form"}>
            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Email address
              </FormLabel>
              <Input
                py={"22px"}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
                type={"email"}
                placeholder="Email address"
              />
            </FormControl>
            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Username
              </FormLabel>
              <Input
                py={"22px"}
                onChange={(e) => {
                  setData({ ...data, username: e.target.value });
                }}
                type={"text"}
                placeholder="Username"
              />
            </FormControl>

            <ModalFooter>
              <Button type={"button"} mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                loadingText={"Saving..."}
                type={"submit"}
                colorScheme="primary"
              >
                Save
              </Button>
            </ModalFooter>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
