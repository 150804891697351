import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { vehiclesService } from "../../services/vehicles";
import { currencyCodes, vehicleTypes } from "../../utils/helpers";
import { driversService } from "../../services/drivers";
import { Spinner } from "@chakra-ui/react";
import { adminService } from "../../services/admins";
import CountUp from "../count-up";

export default function TripInfoModal({
  isOpen,
  onOpen,
  onClose,
  onError,
  onSuccess,
  trip,
  times,
}) {
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);

  const getDrivers = async () => {
    try {
      if (trip?.id) {
        setLoading(true);
        const drivers = await adminService.getDriversByTripId(trip?.id);
        setDrivers(drivers?.data?.drivers || []);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      onError("An error occured, please try again");
    }
  };

  useEffect(() => {
    if (trip?.id) {
      getDrivers();
    }
  }, [trip, times]);

  return (
    <>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Trip Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {loading ? (
              <Center py={"30px"}>
                <Spinner size={"lg"} color="#3e98d4" />
              </Center>
            ) : (
              <Box>
                {trip?.rideType == "HOURS" &&
                  trip?.tripStatus == "PENDING" &&
                  trip?.startedAt && (
                    <CountUp
                      trip={trip}
                      startDate={new Date(trip?.startedAt)}
                    />
                  )}

                <Box mb={"10px"}>
                  <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                    Trip start time:
                  </Text>
                  {trip?.startedAt ? (
                    <Text color={"#000"} fontSize={16}>
                      {new Date(trip?.startedAt).toLocaleDateString() +
                        " - " +
                        new Date(trip?.startedAt).toLocaleTimeString()}
                    </Text>
                  ) : (
                    <Text color={"#000"} fontSize={16}>
                      Not yet started
                    </Text>
                  )}
                </Box>

                {trip?.endedAt && (
                  <Box mb={"10px"}>
                    <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                      Trip end time:
                    </Text>
                    <Text color={"#000"} fontSize={16}>
                      {new Date(trip?.endedAt).toLocaleDateString() +
                        " - " +
                        new Date(trip?.endedAt).toLocaleTimeString()}
                    </Text>
                  </Box>
                )}

                <Box mb={"10px"}>
                  <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                    Passenger Names:
                  </Text>
                  <Text color={"#000"} fontSize={16}>
                    {trip?.passenger?.fullName}
                  </Text>
                </Box>
                <Box mb={"10px"}>
                  <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                    Passenger Phone number:
                  </Text>
                  <Text color={"#000"} fontSize={16}>
                    {trip?.passenger?.phoneNumber}
                  </Text>
                </Box>
                <Box mb={"10px"}>
                  <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                    Pickup location:
                  </Text>
                  <Text color={"#000"} fontSize={16}>
                    {trip?.source}
                  </Text>
                </Box>
                <Box mb={"10px"}>
                  <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                    Drop-off location:
                  </Text>
                  <Text color={"#000"} fontSize={16}>
                    {trip?.destination}
                  </Text>
                </Box>
                <Text
                  color={"#000"}
                  fontWeight={"bold"}
                  fontSize={16}
                  mb={"10px"}
                >
                  Driver
                </Text>
                {drivers?.map((driver, index) => {
                  return (
                    <Flex
                      py={"5px"}
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor:
                          index + 1 === drivers?.length ? "white" : "#BCC5D1",
                      }}
                      columnGap={"20px"}
                      alignItems={"center"}
                    >
                      <Image
                        objectFit={"contain"}
                        width={"70px"}
                        height={"50px"}
                        src={driver?.vehicles[0]?.images[0]}
                      />
                      <Box>
                        <Text>{driver?.fullName}</Text>
                        <Flex alignItems={"center"} columnGap={"10px"}>
                          <Text>{driver?.phoneNumber}</Text>
                          <Box
                            bg={!trip?.driverAccepted ? "#F7A01F" : "#0ED27D"}
                            color={"white"}
                            px={"10px"}
                            pt={"4px"}
                            pb={"6px"}
                            borderRadius={"10px"}
                          >
                            {trip?.driverAccepted ? "Accepted" : "Not accepted"}
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  );
                })}
              </Box>
            )}
            {!loading && drivers?.length == 0 && (
              <Center mb={"20px"}>
                <Text>Trip info not found</Text>
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
