import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Center,
  Skeleton,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { RiDatabase2Fill } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TablePagination from "../../components/common/tablePagination";
import { getRejectionReason, vehicleTypes } from "../../utils/helpers";
import AvailableDriversModal from "../../components/common/availableDriversModal";
import { TbDotsVertical } from "react-icons/tb";
import TripInfoModal from "../../components/common/tripInfoModal";

const RejectedTripsTable = ({
  headers,
  data,
  loading,
  totalNum,
  pageNum,
  setSortBy,
  searching,
  itemsPerPage,
  driverId,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [currentTrip, setCurrentTrip] = useState({});
  const [currentTrip2, setCurrentTrip2] = useState({});
  const [times, setTimes] = useState(1);
  const [times2, setTimes2] = useState(1);
  useEffect(() => {
    setCurrentPage(pageNum);
  }, [pageNum]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagNation = useRef();
  const tab = searchParams.get("tab");
  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <Box minH={"60vh"}>
      <Table className="customers-table">
        <Thead>
          <Tr>
            {headers?.map((header, index) => (
              <Th whiteSpace={"nowrap"} textTransform={"none"}>
                <Text fontWeight={"bold"}>{header?.name} </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            rows?.map((r) => {
              return (
                <>
                  <Tr>
                    {Array.from(
                      { length: headers?.length },
                      (_, i) => i + 1
                    )?.map((d) => {
                      return (
                        <Td>
                          <Box py={"7px"}>
                            <Skeleton
                              startColor={"#F4F4F4"}
                              borderRadius={"20px"}
                              endColor={"#dddddd"}
                              h={"20px"}
                            />
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })
          ) : (
            <>
              {!loading && data?.length == 0 ? (
                <>
                  <Tr _hover={{ bg: "white !important" }}>
                    <Td colSpan={12} bg={"white"} _hover={{ bg: "white" }}>
                      <Center bg={"white"} py={"45px"} w={"100%"} minW={"100%"}>
                        {searching ? (
                          <Text>No results found</Text>
                        ) : (
                          <>
                            <Center>
                              <Box textAlign={"center"}>
                                <Center mb={"18px"}>
                                  <Box
                                    py={6}
                                    px={6}
                                    borderRadius={"10px"}
                                    bg={"#F2F4F6"}
                                  >
                                    <RiDatabase2Fill
                                      color={"#bcc5d1"}
                                      size={35}
                                    ></RiDatabase2Fill>
                                  </Box>
                                </Center>
                                <Text
                                  fontSize={"19px"}
                                  fontWeight={500}
                                  mb={"9px"}
                                  color={"#637184"}
                                >
                                  Trips
                                </Text>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No trips available, once they are available
                                  they will appear right here.
                                </Text>
                              </Box>
                            </Center>
                          </>
                        )}
                      </Center>
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  {data?.map((data) => {
                    return (
                      <Tr>
                        <Td>{data?.passenger?.phoneNumber}</Td>
                        <Td className="force-wrap">{data?.source}</Td>
                        <Td className="force-wrap">{data?.destination}</Td>
                        <Td>
                          {
                            vehicleTypes?.find(
                              (v) => v?.value == data?.vehicleType
                            )?.label
                          }
                          {data?.color ? "," + data?.color : ""},{" "}
                          {data?.numberOfPassengers} seats
                        </Td>
                        <Td>
                          <Box
                            textAlign={"center"}
                            color={"#fff"}
                            bg={
                              data?.bookingType == "LATER" ? "#000" : "#0ED27D"
                            }
                            py={2.5}
                            px={2}
                            borderRadius={8}
                          >
                            {data?.bookingType}
                          </Box>
                        </Td>
                        <Td className="force-wrap">
                          {getRejectionReason(data?.requestData?.reason)}
                        </Td>
                        <Td>
                          {new Date(data?.tripDate).toLocaleDateString() +
                            " - " +
                            new Date(data?.tripDate).toLocaleTimeString()}
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>

      {totalNum > itemsPerPage ? (
        <Box ref={pagNation} className="pag-cont bottom-0 w-full pb-20">
          <TablePagination
            length={totalNum}
            initialPage={parseInt(currentPage)}
            currentItems={data?.length}
            pageNum={pageNum}
            itemsPerPage={itemsPerPage}
            setPage={(page) => {
              setCurrentPage(parseInt(page));
              navigate(
                `/drivers/performance/${driverId}?page=${page}&tab=${tab}`
              );
            }}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default RejectedTripsTable;
